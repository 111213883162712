import React from 'react'
import styled from 'styled-components'
import { HightLight } from './HightLight'
import { Banner } from './Banner'
import { LocationMapSection } from './LocationMapSection'
import { Locations } from './LocationsSection'
import { Stories } from './Stories'
import { DraperEnterpreneurialNetwork } from './DraperEnterpreneurialNetwork'
import { Why } from './WhySection'
import { Ventures } from './Ventures'
import { Subscribe } from './Subscribe'

const MainContent = ({ children, data }) => {
  const listLocation = data?.body2[0]?.items.sort((a, b) => a?.location?.document?.data?.city?.text.localeCompare(b?.location?.document?.data?.city?.text))
  if (!data) return null;
  return (
    <WrapperSection>
      <Banner
        title={data?.banner_title}
        description={data?.banner_description?.text}
        cta={data?.banner_cta}
        banner={data?.banner}
      />
      <HightLight
        data={data?.body1[0] === undefined ? null : data?.body1[0]?.primary}
      />
      <LocationMapSection
        data={data?.body2[0] === undefined ? null : data?.body2[0]?.primary}
        geolocations={listLocation}
      />
      {listLocation && (
      <Locations listItem={listLocation} />
      )}
      <Why
        items={data?.body3[0] === undefined ? null : data?.body3[0]?.items}
        primary={data?.body3[0] === undefined ? null : data?.body3[0]?.primary}
      />
      <DraperEnterpreneurialNetwork
        data={data?.body4[0] === undefined ? null : data?.body4[0]?.primary}
      />
      {data?.body5[0] !== undefined &&
        data?.body5[0]?.items &&
        data?.body5[0]?.items.map((item, i) => (
          <Ventures
            type={i === 0 ? "ventures" : i === 1 ? "academy" : "service"}
            key={i}
            primary={item}
          />
        ))}
      <Stories data={data?.body6[0]} />
      <Subscribe
        title={data?.subscribe_title}
        description={data?.subscribe_description}
        button={data?.subscribe_button_label}
      />
    </WrapperSection>
  );
}

export default MainContent;

const WrapperSection = styled.div`
  height: 100%;
`;
