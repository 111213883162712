import React from "react";
import styled from "styled-components";
import { color } from "../../../shared/style";
import { SubscribeForm } from "./SubscribeForm";

export const Subscribe = ({ title, description, button }) => {
  return (
    <WrapperSection>
      <WrapperSubcribe>
        <SubscribeForm
          title={title}
          description={description}
          button={button}
        />
      </WrapperSubcribe>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  background-color: ${color.primary.white};
  max-width: 1500px;
  padding: 80px 131px;
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 1499px) {
    padding: 80px 8.4vw;
  }
  @media (max-width: 650px) {
    padding: 70px 20px;
    flex-direction: column;
  }
`;
const WrapperSubcribe = styled.div`
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
