import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Header } from "../containers/HeaderContainer";
import FooterContainer from "../containers/FooterContainer";
import useApp, { withAppContext } from "../contexts/context";
import { ThemeProvider } from "styled-components";
import { useSiteMetadata } from "../hooks/useQuerydata";
import HomeContainer from '../containers/HomeContainer'
import { Sidebar } from "../components/Sidebar";
import { useQueryHomepage } from "../hooks/useQueryHome";

const Homepage = ({ data, children }) => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const { allPrismicHomepage } = useQueryHomepage()
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const dataHome = allPrismicHomepage?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  // if (!data) return null
  // const dataCity = data.allPrismicLocationSpace.nodes;
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={dataHome?.body} />
        }
      >
        <HomeContainer data={dataHome} />
        <script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=draper-startup-house"></script>
      </Layout>
    </ThemeProvider>
  );
};



export default withAppContext(Homepage);

// export const query = graphql`
//   query Query {
//     allPrismicLocationSpace {
//       nodes {
//         uid
//         data {
//           city {
//             text
//           }
//           country {
//             text
//           }
//         }
//       }
//     }
//   }
// `
