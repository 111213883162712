import { useStaticQuery, graphql } from "gatsby"

export const useQueryHomepage = () => {
  const { allPrismicHomepage } = useStaticQuery(
    graphql`
      query QueryHomePage {
        allPrismicHomepage {
          nodes {
            data {
              banner {
                url
                alt
              }
              banner_title {
                html
                text
                raw
              }
              banner_description {
                html
                text
                raw
              }
              banner_cta {
                button_label {
                  html
                  text
                  raw
                }
                button_link {
                  url
                  link_type
                  type
                }
              }
              body6 {
                ... on PrismicHomepageBody6DshStory {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    blog {
                      document {
                        ... on PrismicBlogDetail {
                          uid
                          data {
                            image {
                              alt
                              url
                            }
                            release_date(formatString: "DD MMM YYYY")
                            title {
                              html
                              text
                              raw
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              body1 {
                ... on PrismicHomepageBody1Highlight {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    link_label {
                      html
                      text
                      raw
                    }
                    link {
                      url
                      link_type
                    }
                  }
                }
              }
              body2 {
                ... on PrismicHomepageBody2ExploreLocation {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    location {
                      document {
                        ... on PrismicLocationSpace {
                          uid
                          data {
                            thumbnail {
                              alt
                              url
                            }
                            city {
                              html
                              text
                              raw
                            }
                            country {
                              html
                              text
                              raw
                            }
                            tags {
                              tag_name
                            }
                            geo_location {
                              latitude
                              longitude
                            }
                            body5 {
                              ... on PrismicLocationSpaceBody5AddressAndContacts {
                                primary {
                                  phone_number {
                                    html
                                    text
                                    raw
                                  }
                                  email {
                                    html
                                    text
                                    raw
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              body3 {
                ... on PrismicHomepageBody3Why {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                  }
                  items {
                    icon {
                      url
                      alt
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body4 {
                ... on PrismicHomepageBody4Den {
                  primary {
                    image {
                      url
                      alt
                    }
                    den_logo {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    button_label {
                      text
                    }
                    button_link {
                      url
                      link_type
                      type
                    }
                  }
                }
              }
              body5 {
                ... on PrismicHomepageBody5MediaBox {
                  items {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    button_label {
                      text
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                    content {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    image_position
                    shadow_color
                    background_color_content
                  }
                }
              }
              body {
                ... on PrismicHomepageBodyGeneralCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    robot {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    googlebot {
                      html
                      text
                      raw
                    }
                    favicon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    bingbot {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicHomepageBodyOpenGraph {
                  primary {
                    og_url {
                      url
                      type
                      link_type
                    }
                    og_type {
                      html
                      text
                      raw
                    }
                    og_title {
                      html
                      text
                      raw
                    }
                    og_site_name {
                      html
                      text
                      raw
                    }
                    og_locale {
                      html
                      text
                      raw
                    }
                    og_image_width {
                      html
                      text
                      raw
                    }
                    og_image_height {
                      html
                      text
                      raw
                    }
                    og_image {
                      alt
                      url
                    }
                    og_description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicHomepageBodyTwitterCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicHomepageBodyPinterestProductPin {
                  slice_type
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price
                    currency
                    availability
                  }
                }
              }
              subscribe_title {
                html
                text
                raw
              }
              subscribe_description {
                html
                text
                raw
              }
              subscribe_button_label {
                text
              }
            }
          }
        }
      }
    `
  );

  return {
    allPrismicHomepage
  }
}