import React from "react";
import styled from "styled-components";
// import { color } from "../../../shared/style";

export const ReactMailchimpEmailSignupForm = ({
  elementId,
  url,
  title,
  subtitle,
  labelBtn,
}) => (
  <Container>
    <div className="subscribe-container">
      <div id={`${elementId}mc_embed_signup`}>
        <form
          action={url}
          method="post"
          id={`${elementId}mc-embedded-subscribe-form`}
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id={`${elementId}mc_embed_signup_scroll`}>
            <div className="subscribe-container__box">
              <label className="subscribe-container__title" htmlFor="mce-EMAIL">
                {title}
              </label>

              {subtitle && (
                <p className="subscribe-container__subtitle">{subtitle}</p>
              )}
            </div>
            <div className="box-subscribe">
              <input
                type="email"
                defaultValue=""
                name="EMAIL"
                className="subscribe-container__email"
                id={`${elementId}mce-EMAIL`}
                placeholder="Enter your email adress"
                required
              />
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_5f0b91c96bbdf35913a136639_ddfba3375e"
                  tabIndex={-1}
                  defaultValue=""
                />
              </div>
              <button
                type="submit"
                defaultValue="Subscribe"
                name="subscribe"
                id={`${elementId}mc-embedded-subscribe`}
                className="subscribe-container__submit"
              >
                {labelBtn}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Container>
);
const Container = styled("div")`
  .subscribe-container {
    background: #ffff;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0rem;
    max-width: 1500px;
    padding: 0;
    width: 100%;
    &,
    & * {
      display: flex;
      align-items: center;
      @media (max-width: 650px) {
        flex-direction: column;
      }
    }

    form {
      margin-bottom: 0;
    }
    &__box {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 9.78vw;
      width: 42.5vw;
      @media (min-width: 1500px) {
        width: auto;
        padding-right: 150px;
      }
      @media (max-width: 1440px) {
        width: auto;
      }
      @media (max-width: 650px) {
        width: 100%;
        padding-right: 0;
      }
      .subscribe-container__title {
        color: #204370;
        display: inline-block;
        font-size: 56px;
        font-weight: 500;
        text-transform: uppercase;
        font-family: Alternate Gothic No1 D;
        letter-spacing: -0.015em;
        line-height: 56px;
        margin-bottom: 0;
        @media (max-width: 650px) {
          font-size: 62px;
          line-height: 62px;
        }
      }
      .subscribe-container__subtitle {
        font-size: 16px;
        line-height: 26px;
        color: #204370;
        font-family: Oxygen;
        padding-top: 24px;
        font-weight: 400;
        padding-bottom: 0;
        width: 100%;
        margin-block-start: 0;
        margin-bottom: 0;
        @media (max-width: 650px) {
          width: 100%;
          padding-top: 14px;
        }
      }
    }
    .box-subscribe {
      @media (max-width: 1080px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
      .subscribe-container__email {
        height: fit-content;
        outline: none;
        box-sizing: border-box;
        background-color: #F4F6F8;
        border-radius: 50px;
        color: rgba(35, 35, 39, 0.5);
        /* cursor: pointer; */
        font-size: 16px;
        font-family: Oxygen;
        line-height: 26px;
        font-weight: 400;
        margin-bottom: 0;
        justify-content: center;
        padding: 20px 26px 19px;
        width: 26.4vw;
        max-width: 361px;
        margin-right: 14px;
        transition: border-color 0.3s ease-out;
        @media (max-width: 1080px) {
          margin-right: 0;
          max-width: 100%;
          width: 42vw;
        }
        @media (max-width: 650px) {
          width: 100%;
          margin-top: 40px;
        }
      }
      .subscribe-container__submit {
        background-color: #ffcc00;
        border-radius: 40px;
        color: #232327;
        border: none;
        cursor: pointer;
        font-size: 16px;
        white-space: nowrap;
        font-family: Oxygen;
        line-height: 26px;
        font-weight: 700;
        justify-content: center;
        transition: background-color 0.3s ease-in-out;
        padding: 19.5px 45px;
        border: 1px solid #ffcc00;
        box-sizing: border-box;
        @media (max-width: 1080px) {
          width: 100%;
          margin-top: 14px;
        }
        &:hover {
          background-color: #ffefae ;
          transition: 0.3s;
        }
      }
    }
  }
`;