import 'react-mailchimp-email-signup-form/dist/esm/index.css';

import React from 'react';
import { ReactMailchimpEmailSignupForm } from './ReactMailchimpEmailSignupForm';
// import styled from 'styled-components';

// Here's a usage example when using functional components
export const SubscribeForm = ({ title, description, button }) => (
    <ReactMailchimpEmailSignupForm
        elementId="first-email-signup-form"
        url="https://gmail.us5.list-manage.com/subscribe/post?u=ffb468a23f2adce54d80b68bf&amp;id=80fa29e167"
        title={title?.text}
        subtitle={description?.text}
        labelBtn={button?.text}
    />
);
